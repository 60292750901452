.page {
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.panel {
    overflow: hidden;
    max-width: 375px;
    border-radius: 8px;
    background: #fff;

    @media (--smUp) {
        width: 375px;
    }
}

.image {
    display: block;
    width: 100%;
}

.content {
    padding: calc(3 * var(--spacing));
}
